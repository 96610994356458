export default {
  "Nav": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bosh sahifa"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biz haqimizda"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xizmatlar"])},
    "packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tur paketlar"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bog'lanish"])}
  },
  "Home": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dunyoni biz bilan kashf eting!"])}
  },
  "About": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biz haqimizda"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birinchi Darajali Parvozlar"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5 yulduzli turar joy"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["150 ta Premium shahar sayohatlari"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoyib Mehmonxonalar"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eng so'nggi rusumdagi avtomobillar"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24/7 xizmat"])}
  },
  "readMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batafsil"])},
  "Services": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bizning xizmatlar"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dunyo bo'ylab sayohatlar"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehmonxonani band qilish"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayohat bo'yicha qo'llanmalar"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tadbirlarni boshqarish"])}
  },
  "Destination": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manzil"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ommabop manzil"])}
  },
  "packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoyib paketlar"])},
  "booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Band qilish"])},
  "booking1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onlayn bron qilish"])},
  "booking2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turni bron qilish"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ismingiz"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektron manzilingiz"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sana va vaqt"])},
  "destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manzil"])},
  "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maxsus so'rov"])},
  "bookNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozir band qiling"])},
  "process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jarayon"])},
  "steps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 ta oson qadam"])},
  "process1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgilangan manzilni tanlang"])},
  "process2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onlayn to'lang"])},
  "process3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bugun uching"])},
  "travelGuide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayohat qo'llanmasi"])},
  "travelGuide2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yo‘lboshchilarimiz bilan tanishing"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namangan viloyati, Uychi tumani, A.Temur ko'cha"])},
  "signUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ro'yxatdan o'tish"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qidirish"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har qanday so'rov uchun murojaat qiling"])},
  "contact1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bog'laning"])},
  "contact2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
  "contact3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ofis"])},
  "contact4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "contact5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xabar Yuborish"])},
  "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mavzu"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xabar"])},
  "about1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Umar Lyuks Travel” sayyohlik kompaniyasi bo'lib, turizmning kirish va chiqish sohalari uchun ishlaydi. Biz sayohatchilar uchun dunyoning barcha yetakchi mamlakatlari, shuningdek, O'zbekiston bo'ylab turlar tashkil qilamiz. Biz O'zbekistonga individual va guruhli turlar, infoturlar tashkil etishga ixtisoslashganmiz."])},
  "about2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ijtimoiy mas'uliyatli turoperator sifatida biz sayyohlarni qabul qiladigan hududlarda mahalliy aholining turmush sharoitini yaxshilashga qaratilgan ijtimoiy loyihalarni (masalan, ichimlik suvi tizimini yaxshilash, maktablarni ta'mirlash va h.k.) ham tashkil etamiz."])},
  "home1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bizning asosiy vazifamiz sayyohlar uchun sayohatni imkon qadar SIFATLI va ARZON qilishdir"])},
  "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayohat paketlari sahifasidan o'zingizga yoqqan manzilni tanlang"])},
  "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To'lov tizimlari orqali onlayn to'lovni amalga oshiring"])},
  "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orzuyingizdagi manzil tomon bugunoq uchib keting"])},
  "book1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Umar Lyuks Travel” 2023 yildan tashkiliy va sayyohlik xizmatlari bozorida ishbilarmonlik aloqa agentligi."])},
  "book2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kompaniya jamoasi tadbirlarni tashkil etishda ajoyib muvaffaqiyatlarga erishgan mutaxassislardan iborat bo'lib, bu ijodiy yondashuvga ega bo'lgan malakali, mas'uliyatli va professional tashkilotchining obro'si bilan tasdiqlanadi."])},
  "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menyu"])},
  "gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galereya"])},
  "newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xabarnoma"])},
  "summa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To'lanadigan summa"])},
  "cardType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karta turi"])},
  "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To'lash"])},
  "onlinePayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online ariza"])},
  "tourismType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ichki va Ziyorat turizmi"])},
  "service1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viza xizmatlari"])},
  "service2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aviabiletlar"])},
  "service3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temir yo'l chiptalari"])},
  "service4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transport xizmatlari"])},
  "paymentTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To'lov turlari"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yuborish"])}
}